<template>
  <TTView>
    <VRow>
      <VCol>
        <InvitationForm
          :entity="invitation"
          :companies="companies"
          @update:companyId="invitation.companyId = $event"
          @update:serviceName="invitation.serviceName = $event"
          @update:visibility="invitation.visibility = $event"
          @update:defaults="invitation.defaults = $event"
          @update:schema="invitation.schema = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_INVITATION } from '../../components/invitations/common';
import InvitationForm from '../../components/invitations/InvitationForm.vue';

export default {
  name: 'InvitationsEdit',

  components: {
    InvitationForm,
  },

  data() {
    return {
      companies: [],
      invitation: { ...DEFAULT_INVITATION },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { invitationId: id } = this.$route.params;
        const data = { id };
        const [companiesResponse, invitationResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.Invitations.InvitationGet(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.invitation = invitationResponse.invitation || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const { invitation } = this;
        const { id } = invitation;
        delete invitation.id;
        const data = { id, invitation };
        await this.$di.api.Invitations.InvitationUpdate(data);
        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
