<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.companyId"
                :items="companies"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Компания"
                @input="$emit('update:companyId', $event)"
                @keydown.enter="handleSubmit"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.serviceName"
                label="Имя сервиса"
                @input="$emit('update:serviceName', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.visibility"
                :items="visibilities"
                item-text="title"
                item-value="value"
                label="Видимость"
                @input="$emit('update:visibility', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.defaults"
                label="defaults"
                @input="$emit('update:defaults', $event)"
              />

              <VAlert
                colored-border
                border="left"
                type="info"
                elevation="1"
              >
                <strong>defaults</strong> - должен быть "валидным" JSON
              </VAlert>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.schema"
                label="Схема"
                @input="$emit('update:schema', $event)"
              />

              <VAlert
                colored-border
                border="left"
                type="info"
                elevation="1"
              >
                <strong>Схема</strong> - JSON SCHEMA
              </VAlert>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_INVITATION, VISIBILITIES_LIST } from './common';

export default {
  name: 'InvitationForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_INVITATION }),
    },

    companies: {
      type: Array,
      required: true,
    },

    visibilities: {
      type: Array,
      default: () => VISIBILITIES_LIST,
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание приглашения';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование приглашения';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
